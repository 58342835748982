import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-h6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_FormLoading = _resolveComponent("FormLoading")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    modelValue: _ctx.show,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.show) = $event))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, { style: {"width":"90vw","max-width":"900px"} }, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.row.id ? "Editar Divisão" : "Nova Divisão"), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_q_separator),
          _createVNode(_component_q_form, { onSubmit: _ctx.submit }, {
            default: _withCtx(() => [
              _createVNode(_component_q_card_section, null, {
                default: _withCtx(() => [
                  _createVNode(_component_q_input, {
                    dense: "",
                    outlined: "",
                    "lazy-rules": "",
                    class: "col-12",
                    label: "Nome *",
                    modelValue: _ctx.form.descricao,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.descricao) = $event)),
                    rules: [_ctx.requiredField]
                  }, null, 8, ["modelValue", "rules"]),
                  _createVNode(_component_q_input, {
                    dense: "",
                    outlined: "",
                    "lazy-rules": "",
                    type: "textarea",
                    class: "col-12",
                    label: "Resumo",
                    modelValue: _ctx.form.resumo,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.resumo) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_q_separator),
              _createVNode(_component_q_card_actions, { align: "right" }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_btn, {
                    type: "submit",
                    color: "primary",
                    class: "q-ma-sm",
                    icon: "save",
                    label: "Salvar"
                  }),
                  _withDirectives(_createVNode(_component_q_btn, {
                    class: "q-ma-sm",
                    icon: "close",
                    color: "red",
                    label: "Cancelar"
                  }, null, 512), [
                    [_directive_close_popup]
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["onSubmit"]),
          _createVNode(_component_FormLoading, { loading: _ctx.loading }, null, 8, ["loading"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}