
import { defineComponent } from "vue";
import { requiredField } from "@/util/form-rules";
import FormLoading from "../../layout/FormLoading.vue";
import useForm from "@/modules/useForm";
import Divisao from "@/interfaces/Divisao";

interface Form {
  descricao: string;
  resumo: string | null;
}

const baseForm: Form = {
  descricao: "",
  resumo: "",
};

export default defineComponent({
  components: { FormLoading },
  setup(props, { emit }) {
    const { show, form, row, loading, submit, afterSubmit } = useForm(
      "divisoes",
      "divisões",
      "a",
      baseForm,
      emit,
      "divisoes"
    );

    const open = (data: Divisao | null): void => {
      if (data) {
        row.value = JSON.parse(JSON.stringify(data));
        if (row.value?.id) {
          form.value = {
            descricao: row.value.descricao,
            resumo: row.value.resumo,
          };
        } else {
          form.value = JSON.parse(JSON.stringify(baseForm));
        }
      }
      show.value = true;
    };

    return {
      show,
      open,
      form,
      row,
      requiredField,
      loading,
      submit,
      afterSubmit,
    };
  },
});
