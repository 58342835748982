
import { defineComponent } from "vue";
import DivisaoColumn from "@/util/table-columns/DivisaoColumn";
import DivisaoForm from "@/components/admin/divisoes/DivisaoForm.vue";
import useList from "@/modules/useList";

export default defineComponent({
  components: { DivisaoForm },
  setup() {
    const {
      rows,
      loading,
      filter,
      columns,
      list,
      formatData,
      modalForm,
      openModalForm,
      confirmDelete,
    } = useList("divisoes", DivisaoColumn);

    return {
      rows,
      loading,
      filter,
      columns,
      list,
      modalForm,
      openModalForm,
      formatData,
      confirmDelete,
    };
  },
});
